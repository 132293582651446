.store-marker {
	background-image: url('https://oncall.stmtires.com/modules/core/img/brand/store-icon.svg');
	height: 50px;
	width: 50px;
}
.mapboxgl-ctrl {
	display: none !important;
}

.ta-editor {
    min-height: 300px;
    height: auto;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
    margin:20px 0;
    color: #000000;
}

.yellow {
	color : #EAEA32;
}

.ui-grid-filter-select {
    color: #000000;
}
.mapCover {
    position: absolute;
    background-color: transparent;
    height: 100%;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
}
.rolling-week-controls {
	display : inline-block;
	color : #FFFFFF;
	background-color : none;
	width : auto;
	height : auto;
	line-height : .8em;
}
.rolling-week-controls .glyphicons{
	font-size : .9em;
	color : rgba(255,255,255,0.5);
}
.rolling-week-controls .glyphicons:hover {
	color : rgba(255,255,255,1.0);
	text-shadow: 1px 1px 2px #000000;
}
.rolling-week-controls .glyphicons::before {
	padding : 0 0 0 0;
	margin : 0;
}
.week-start-week-end {
	display : inline-block;
	padding : 0 0 0 0;
	margin : 0;
	font-size : .8em;
}
.scheduling-plan {
	width : 100%;
	height : 310px;
	font-size : .9em;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	overflow : hidden;
}
.scheduling-plan-cell {
	color : #000000;
	background-color : #FFFFFF !important;
}
.scheduling-plan-cell-clickable {
	color : #000000;
	background-color : #FFFFFF !important;
	cursor : pointer;
}
.scheduling-plan-cell-clickable:hover {
	font-weight : 700;
	background-color : #EEEEEE !important;
}
.primary-contact {
	color : #C71918;
	background-color : #FFFFFF !important;
	cursor : pointer;
}
/* .primary-contact:before {

} */
.primary-contact:hover {
	font-weight : 700;
	background-color : #EEEEEE !important;
}
.first-contact {
	color : #0D4A92;
	background-color : #FFFFFF !important;
	cursor : pointer;
}
.first-contact:hover {
	font-weight : 700;
	background-color : #EEEEEE !important;
}
.second-contact {
	color : #4CA636;
	background-color : #FFFFFF !important;
	cursor : pointer;
}
.second-contact:hover {
	font-weight : 700;
	background-color : #EEEEEE !important;
}
.third-contact {
	color : #762CA7;
	background-color : #FFFFFF !important;
	cursor : pointer;
}
.third-contact:hover {
	font-weight : 700;
	background-color : #EEEEEE !important;
}
.final-contact {
	color : #835732;
	background-color : #FFFFFF !important;
	cursor : pointer;
}
.final-contact:hover {
	font-weight : 700;
	background-color : #EEEEEE !important;
}
.default-cell-header {
	background-color : #CCCCCC;
	color : #FFFFFF;
}
.primary-contact-cell-header {
	background-color : #CCCCCC;
	color : #C71918;
}
.first-backup-contact-cell-header {
	background-color : #CCCCCC;
	color : #0D4A92;
}
.second-backup-contact-cell-header {
	background-color : #CCCCCC;
	color : #4CA636;
}
.third-backup-contact-cell-header {
	background-color : #CCCCCC;
	color : #762CA7;
}
.final-contact-cell-header {
	background-color : #CCCCCC;
	color : #835732;
}

.active-day {
	background-color: ghostwhite !important;
	border-top: thin solid lightgray !important;
	border-bottom: thin solid lightgray !important;
}
.active-day-left {
	border-left: medium solid gray !important;
}
.active-day-right {
	border-right: medium solid gray !important;
}
.closed-day {
	/*border-top:medium solid red !important;*/
	/*border-bottom:medium solid red !important;*/
	background-color : #FFDDDD !important;
}
.closed-day:hover {
	background-color : #FFDDDD !important;
}
.closed-day-left{
	border-left:medium solid red !important;
}

.closed-day-right{
	border-right:medium solid red !important;
}

.open-day {
	/*border-top:medium solid red !important;*/
	/*border-bottom:medium solid red !important;*/
	background-color : #DDFFDD !important;
}
.open-day:hover {
	background-color : #DDFFDD !important;
}
.open-day-left{
	border-left:medium solid green !important;
}

.open-day-right{
	border-right:medium solid green !important;
}
.schedule-input{
    height: 38px;
}

.contact-top {
	padding: 15px;
	font-size: 22px;
}
.contact-bottom {
	border-top: 1px solid rgba(0,0,0,0.12);
	padding: 15px;
	font-size: 22px;
}
.other-schedule-info {
	padding : 10px;
	font-size : 1.7em;
	line-height : 1.3em;
}
.schedule-modal {
	/*padding : 5px 10px;*/
	width : 40%;
	max-width : 500px;
	height : auto;
   /*top:  85px;*/
   /*position: absolute;*/
}
.schedule-modal .header-text {
	font-size : 1.3em;
}
.schedule-modal .glyphicons{
	font-size : 1.7em;
}
.schedule-modal h2 {
	text-align: center;
}
.schedule-modal ul {
	list-style: none;
	-webkit-padding-start: 0px;
}
.schedule-modal ul li .info-label {
	display : inline-block;
	/*font-size: .9em;*/
	text-align: right;
	/*padding-right: 4px;*/
	color : #929292;
}
.schedule-modal ul li .info-data {
	display : inline-block;
	/*font-size: .9em;*/
	font-weight: 700;
	text-align: left;
	padding-left: 10px;
}
.schedule-modal ul li .info-note {
	display : inline-block;
	font-style: italic;
	/*font-size: .9em;*/
	color : #929292;
}
.open-store-modal-content {
	padding :10px;
}
.no-contact-modal {
    /*padding : 5px 10px;*/
    width : auto;
    height : auto;
}
.no-contact-modal .header-text {
    font-size : 1.3em;
}
.ui-grid-schudel-container{
	overflow: auto;
}
.directory-ui-gird-container{
	overflow: auto;
}
.i-open-button{
	overflow: auto;
}
md-tooltip .md-content {
	height: auto;
}

.store-search-container {
	overflow: hidden;
}

.inactive-checkbox {
	padding: 0 16px;
	margin-top: -10px;
}

.inactive-store-name {
	color: lightgray !important;
}

.inactive-txt {
	color: red;
	font-weight: bold;
	font-style: italic;
}

.schedule-icon-cell {
	text-overflow: clip;
	padding: 5px 0;
	font-size: 12px;
}

.copy-button-header .ui-grid-header-cell-label {
	font-size: 0;
}

.contact-remove-btn {
	text-align: center;
	font-size: 12px;
	position:absolute;
	top: 8px;
	right: 3px;
	color: gray;
}

.contact-name-input {
	position: relative;
}

.store-time-info {
	text-align: left;
	margin-bottom: 10px;
	padding-left: 10px;
	font-size: 12px;
}

.store-time {
	font-size: 20px;
}

.store-time-status {
	font-weight: bolder;
}

.current-time {
	font-weight:bold;
	font-family: "Courier New", Courier, monospace
}

.current-time-ampm {
	margin: 0 5px 0 2px;
}

.store-search-container .ui-grid-search .ui-grid-top-panel input {
	color: black;
}
