/**********************************************************************/
/* GLOBAL CSS */
/**********************************************************************/
@import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700);
@import url(https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css);

@font-face {
	font-family: 'ui-grid';
	src: url('../webfonts/ui-grid.eot');
	src: url('../webfonts/ui-grid.eot#iefix') format('embedded-opentype'),
		 url('../webfonts/ui-grid.woff') format('woff'),
		 url('../webfonts/ui-grid.ttf?') format('truetype'),
		 url('../webfonts/ui-grid.svg?#ui-grid') format('svg');
	font-weight: normal;
	font-style: normal;
  }

/*.content {*/
	/*margin-top: 50px;*/
/*}*/


/* Spacing */
.mt-1 {
    margin-top: 10px;
}
.mb-1 {
    margin-bottom: 10px;
}
.ml-1 {
    margin-left: 10px;
}
.mr-1 {
    margin-right: 10px;
}


.undecorated-link:hover {
	text-decoration: none;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
	display: none !important;
}

[ng-click] {
	cursor:pointer;
}

html,body {
	font-family: 'Droid Sans', sans-serif;
	font-size: 14px;
	background-color : #D8D8D8;
}

:focus{
	outline: none !important;
}

.dBorder {
	box-sizing: border-box;
	border : thin solid black;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.disabled-div {
	pointer-events: none;
	opacity: 0.4;
}

/**********************************************************************/
/* COLOR DEFS */
/**********************************************************************/
.blue-bg {
	background-color : #0D4A92 !important;
}
.blue {
	color : #0D4A92 !important;
}
.red-bg {
	background-color : #C71918 !important;
}
.red {
	color : #C71918 !important;
}
.litered-bg {
	background-color : #C78784 !important;
}
.litered {
	color : #C78784 !important;
}
.green-bg {
	background-color : #2EA623 !important;
}
.green {
	color : #2EA623 !important;
}
.litegreen-bg {
	background-color : #88BC85 !important;
}
.litegreen {
	color : #88BC85 !important;
}
.purple-bg {
	background-color : #762CA7 !important;
}
.purple {
	color : #762CA7 !important;
}
.purple-bg {
	background-color : #762CA7 !important;
}
.brown-bg {
	background-color : #835732 !important;
}
.brown {
	color : #835732 !important;
}
.black-bg {
	background-color : #000000 !important;
}
.black {
	color : #000000 !important;
}
.white-bg {
	background-color : #FFFFFF !important;
}
.white {
	color : #FFFFFF !important;
}
.litegrey-bg {
	background-color : #E6E6E6 !important;
}
.litegrey {
	color : #E6E6E6 !important;
}
.darkgrey-bg {
	background-color : #999999 !important;
}
.darkgrey {
	color : #999999 !important;
}
/**********************************************************************/
/* APP FRAMEWORK CSS */
/**********************************************************************/
.notes-oncall-modal {
	/*border-radius : 15px;*/
	/*padding : 5px;*/
	width : 50%;
	max-height : 500px;
}
.notes-oncall-modal .header-text {
	font-size : 1.3em;
}
#text {
	overflow : hidden;
}
/*.oncall-btn {*/
	/*display : inline-block;*/
	/*width : auto;*/
	/*height : 30px;*/
	/*border-radius : 15px;*/
	/*padding : 3px 10px 0 10px;*/
	/*margin : 3px;*/
	/*-webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);*/
	/*-moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);*/
	/*-ms-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);*/
	/*-o-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);*/
	/*box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);*/
	/*-webkit-box-sizing: border-box;*/
	/*-moz-box-sizing: border-box;*/
	/*-ms-box-sizing: border-box;*/
	/*-o-box-sizing: border-box;*/
	/*box-sizing: border-box;*/
	/*-moz-user-select: none;*/
	/*-webkit-user-select: none;*/
	/*-ms-user-select:none;*/
	/*-o-user-select:none;*/
	/*user-select:none;*/
	/*cursor : pointer;*/
/*}*/
/*.oncall-btn:active {*/
	/*-webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.7);*/
	/*-moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.7);*/
	/*box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.7);*/
/*}*/
/*.btn-red {*/
	/*background-color: #C71918;*/
	/*border : thin solid #B41918;*/
/*}*/
/*.btn-red:hover {*/
	/*background-color: #DC2A2B;*/
/*}*/
/*.btn-red:active {*/
	/*background-color: #B41918;*/
	/*color: #CCCCCC;*/
/*}*/
/*.btn-green {*/
	/*background-color: #4CA636;*/
	/*border : thin solid #499633;*/
/*}*/
/*.btn-green:hover {*/
	/*background-color: #53C93D;*/
/*}*/
/*.btn-green:active {*/
	/*background-color: #499633;*/
	/*color: #CCCCCC;*/
/*}*/
/*.btn-blue {*/
	/*background-color: #0D3EB9;*/
	/*border : thin solid #0D3996;*/
/*}*/
/*.btn-blue:hover {*/
	/*background-color: #2058E0;*/
/*}*/
/*.btn-blue:active {*/
	/*background-color: #0D3996;*/
	/*color: #CCCCCC;*/
/*}*/
/**********************************************************************/
/* APP SPECIFIC CSS */
/**********************************************************************/
.app-container {
	width : 100%;
	max-width : 1920px;
	margin-top: 15px;
	padding: 0;
	/*height : auto;*/
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
}

@media screen and (min-width: 960px) {
	.app-container {
		margin-left : auto;
		margin-right : auto;
		padding : 15px;
		margin-top: 50px;
		
	}
}

/* .left-side-widgets {

} */
.right-side-widgets {
	padding-left : 30px;
}
.menu-container {
	width : 100%;
	max-width : 1920px;
	margin-left : auto;
	margin-right : auto;
	padding : 0 15px;
	display: flex;
    align-items: center;
}
.dropdown-menu > li > span {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.5em;
	color: #333333;
	white-space: nowrap;
}
.dropdown-menu > li > span:hover {
	background-color : #EEEEEE;
}
.still-editing-warning-modal {
	position: fixed;
	top: 50px;
	left: 44%;
	padding: 10px;
	background-color: #000000;
	color: #FFFFFF;
	z-index: 150;
	width: auto;
	height: auto;
	overflow: hidden
}
.org-img-container {
	text-align: center;
}
.org-logo{
	height : 35px;
	width : auto;
	/* max-width: 120px; */
}
.small-org-logo {
	height : 35px;
	width : auto;
	max-width: 120px;
}
.admin-dashboard-org-logo-container {
	width : 120px;
	height : auto;
	margin : 5px 10px;
	text-align : center;
	overflow : hidden;
}
.org-view-logo-container {
	width : auto;
	height : auto;
	overflow: hidden;
	margin : 10px;
	padding : 10px;
	max-width : 300px;
}
.org-view-logo-container .thumb {
	width : 100%;
	height : auto;
}
.org-view-logo {
	width : 100%;
	height : auto;
}
.menu-org-logo-container {
	display : inline-block;
	width : auto;
	max-height : 60px;
	margin : 5px 10px 5px 0;
	overflow : hidden;
	/* float : left; */
}
.menu-org-home-container {
	display : inline-block;
	width : auto;
	max-height : 60px;
	margin : 5px 30px 5px 0;
	overflow : hidden;
	/* float : left; */
	color: #c4c4c4;
}
.search-page-logo {
	width : 40%;
	height : auto;
	max-width : 500px;
	margin : 5px auto 5px auto;
	overflow : hidden;
}
.password-container {
	padding : 10px;
	width : 100%;
	height : auto;
	overflow : hidden;
}
.password-container .form-group {
	margin : 0 0 15px 0;
}
.admin-dashboard-container {
	background-color : #FFFFFF;
}
.admin-dashboard-container .title-container {
	padding : 5px 20px;
	width : 100%;
	height : auto;
	overflow : hidden;
	text-align : center;
}
.admin-dashboard-tab-container {
	padding: 10px 0 10px 10px;
	border-bottom: thin solid lightgray;
}
.admin-dashboard-tab {
	display:inline;
	font-size: 14px;
	font-weight: 500;
	text-align:center;
	color: rgba(0,0,0,0.54);
	text-transform: uppercase;
	padding: 12px 24px 10px 24px;
}

.admin-dashboard-tab-selected {
	color: rgb(63, 81, 181);
	border-bottom: 2px solid rgb(255, 64, 129);
}

.title-container .title {
	display : inline-block;
	font-size : 2em;
}
.oncall-widget {
	padding : 0;
	margin : 0 0 10px 0;
	width : 100%;
	height : auto;
}
.oncall-widget-header {
	width : 100%;
	height : 30px;
	border-radius : 15px;
	padding : 4px 10px 0 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.widget-label {
	display : inline-block;
	font-size : 1em;
	float : left;
}
.widget-icons-container {
	display : inline-block;
	float : right;
}
.oncall-widget-icon {
	display : inline-block;
	font-size : 1em;
	color : rgba(255,255,255,0.5);
}
.oncall-widget-icon:hover {
	color : rgba(255,255,255,1.0);
	text-shadow: 1px 1px 2px #000000;
}
.oncall-widget-icon .glyphicons:before {
	padding : 0;
	margin : 0;
}
.oncall-widget-edit-cancel-icon {
	display : inline-block;
	font-size : 1em;
	color : rgba(255, 255, 255, 0.5);
	cursor : pointer;
}
.oncall-widget-edit-cancel-icon:hover {
	color : #FF0000;
	text-shadow: 1px 1px 2px #000000;
}
.oncall-widget-edit-cancel-icon .glyphicons:before {
	padding : 2px 0 0 4px;
	margin : 0;
}
.oncall-widget-edit-save-icon {
	display : inline-block;
	font-size : 1em;
	color : rgba(255, 255, 255, 0.5);
	cursor : pointer;
}
.oncall-widget-edit-save-icon:hover {
	color : #00FF00;
	text-shadow: 1px 1px 2px #000000;
}
.oncall-widget-edit-save-icon .glyphicons:before {
	padding : 2px 0 0 4px;
	margin : 0;
}
/*.oncall-widget-body {*/
	/*position: relative;*/
	/*border-width: 1px;*/
	/*border-style : solid;*/
	/*border-color : #737373;*/
	/*border-radius : 15px;*/
	/*width : 100%;*/
	/*height : auto;*/
	/*font-size : .9em;*/
	/*z-index:10;*/
    /*overflow : hidden;*/
	/*-webkit-transition-duration : 0.3s;*/
	/*-moz-transition-duration : 0.3s;*/
	/*-ms-transition-duration : 0.3s;*/
	/*-o-transition-duration : 0.3s;*/
	/*transition-duration : 0.3s;*/
	/*-webkit-transition-timing-function : ease-in-out;*/
	/*-moz-transition-timing-function : ease-in-out;*/
	/*-ms-transition-timing-function : ease-in-out;*/
	/*-o-transition-timing-function : ease-in-out;*/
	/*transition-timing-function : ease-in-out;*/
/*}*/
/*.oncall-widget-body-large {*/
    /*position: relative;*/
    /*border-width: 1px;*/
    /*border-style : solid;*/
    /*border-color : #737373;*/
    /*border-radius : 15px;*/
    /*width : 100%;*/
    /*height : auto;*/
    /*padding : 10px;*/
    /*font-size : .9em;*/
    /*z-index:10;*/
    /*overflow : hidden;*/
    /*-webkit-transition-duration : 0.3s;*/
    /*-moz-transition-duration : 0.3s;*/
    /*-ms-transition-duration : 0.3s;*/
    /*-o-transition-duration : 0.3s;*/
    /*transition-duration : 0.3s;*/
    /*-webkit-transition-timing-function : ease-in-out;*/
    /*-moz-transition-timing-function : ease-in-out;*/
    /*-ms-transition-timing-function : ease-in-out;*/
    /*-o-transition-timing-function : ease-in-out;*/
    /*transition-timing-function : ease-in-out;*/
/*}*/
.widget-edit-shadow {
	-webkit-box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.6);
	box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.6);
}
.schedule-legend-icons-container {
	display : inline-block;
	float : right;
}
.schedule-legend-icon{
	display : inline-block;
	font-size : .8em;
	padding-right: 1em;
}
.schedule-legend-icon .glyphicons:before {
	padding : 2px 5px 0 15px;
}
.schedule-legend-icon .glyphicons:after {
	font-size : .8em;
}
.directory-type-icon{
	font-size:1.2em;
}
/* .edit-info {
	margin : 10px;
} */
.edit-info md-input-container {
	width : 100%;
	margin : 0;
}
.edit-info md-input-container .md-errors-spacer {
	height : 0;
	display : none;
}
.edit-info-line {
    display : block;
    margin : 0 0 5px 0;
}
.edit-info-line-group {
	display : block;
	margin-bottom : 5px;
	/*background-color : #EAEAEA;*/
	border-radius : 4px;
	overflow : hidden;
}
.group {
	padding : 0 5px 0 5px;
}
.edit-group-label {
	font-weight : 300;
	color : #6B6B6B;
	padding : 0 5px 3px 0;
}
.edit-group-title {
	/*font-weight : 700;*/
	/*background-color : #999999;*/
	/*color : #FFFFFF;*/
	width : 100%;
	padding : 1px 5px 1px 5px;
	margin-bottom : 2px;
}
/*.new-number-label {*/
	/*display : inline-block;*/
	/*margin-left : auto;*/
	/*margin-right : auto;*/
	/*width : 40%;*/
	/*padding : 0 15px;*/
	/*background-color : #FFFFFF;*/
	/*color : #FF0000;*/
	/*font-size : .8em;*/
	/*font-weight : 700;*/
	/*font-style: italic;*/
	/*text-align: center;*/
/*}*/
.edit-group-title .glyphicons{
	font-size : 1em;
	float : right;
	color : #5E5E5E;
}
.edit-group-title .glyphicons:hover {
	color : #FFFFFF;
}
.edit-group-title .glyphicons::before {
	padding : 2px 0 0 0;
	margin : 0;
}
.edit-info-line .edit-label {
    font-weight : 300;
    color : #ACACAC;
    padding : 0 5px 3px 0;
}
.edit-info-line .form-control {
    display : inline-block;
    height : 22px;
    padding : 0 5px 0 5px;
	margin-bottom : 5px;
	width : 100%;
	font-size : .9em;
	border-radius : 0;
}
.phone-group {
	max-height:400px;
	overflow : auto;
}
.edit-phone-numbers-list {
	list-style: none;
	margin : 0;
	padding : 0;

}
.edit-text-area .form-control {
	display : inline-block;
	width : 100%;
	font-size : .9em;
}
.map-container {
	height : 100px;
	width : 100px;
	border-width: 1px;
	border-style : solid;
	border-color : #737373;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	overflow : hidden;
}
.map-container .map{
	height : 100%;
	width : 100%;
}
.ta-editor {
    height: 100%;
	width : 100%;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
    margin:20px 0;
    color: #FFFFFF;
}
.yellow {
	color : #EAEA32;
}
.mapCover {
    position: absolute;
    background-color: transparent;
    height: 100%;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
}
.oncall-btn {
	display : inline-block;
	width : auto;
	height : 30px;
	border-radius : 15px;
	padding : 3px 10px 0 10px;
	margin : 3px;

	-webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
	-ms-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
	-o-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
	box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;

	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select:none;
	-o-user-select:none;
	user-select:none;

	cursor : pointer;
}
.oncall-btn:active {
	-webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.7);
	-moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.7);
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.7);
}
.btn-red {
	background-color: #C71918;
	border : thin solid #B41918;
}
.btn-red:hover {
	background-color: #DC2A2B;
}
.btn-red:active {
	background-color: #B41918;
	color: #CCCCCC;
}
.btn-green {
	background-color: #4CA636;
	border : thin solid #499633;
}
.btn-green:hover {
	background-color: #53C93D;
}
.btn-green:active {
	background-color: #499633;
	color: #CCCCCC;
}
.btn-blue {
	background-color: #0D3EB9;
	border : thin solid #0D3996;
}
.btn-blue:hover {
	background-color: #2058E0;
}
.btn-blue:active {
	background-color: #0D3996;
	color: #CCCCCC;
}
/* .notes-and-warnings {

} */
.edit-info .notes-and-warnings:hover {
	background-color : #EEEEEE;
}
.note {
	padding : 3px 0 3px 0;
	line-height: 1em;
	font-size : .9em;
}
.add-item-icon {
	color : #A7A7A7;
	margin-bottom : 5px;
}
.add-item-icon:hover {
	color : #999999;
	font-weight : 700;
}
.add-item-icon .glyphicons::before {
	padding : 5px 5px 0 0;
}
.add-item-icon-label {
	display : inline-block;
	/*font-size : .9em;*/
	padding : 3px 0 0 0;
}
.oncall-widget-horizontal-info-header {
	display : inline-block;
	/* float: left; */
	width : 240px;
	height : 34px;
	border-radius : 17px;
	padding : 6px 10px 0 10px;
	margin : 0 10px 0 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.oncall-widget-horizontal-info-body {
	display : inline-block;
	/* float: left; */
	border-width: 1px;
	border-style : solid;
	border-color : #444444;
	border-radius : 17px;
	margin : 0 10px 0 10px;
	width : 400px;
	height : 34px;
	padding : 3px 15px 0 15px;
	margin : 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.oncall-widget-horizontal-info-body .form-control {
	display : inline-block;
	height : 22px;
	padding : 0 5px 0 5px;
	margin-bottom : 5px;
	width : 100%;
	font-size : .9em;
}
.oncall-widget-horizontal-dropdown {
	display : inline-block;
	/* float: right; */
	font-size : 1em;
	border-width: 1px;
	border-style : solid;
	border-color : #444444;
	border-radius : 17px;
	width : 230px;
	height : 34px;
	padding : 4px 15px 0 15px;
	/*padding-top : 8px;*/
	/*text-align: center;*/
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	/*cursor : pointer;*/
}
.oncall-widget-horizontal-dropdown .form-control {
	display : inline-block;
	height : 22px;
	padding : 0 5px 0 5px;
	margin-bottom : 5px;
	width : 100%;
	font-size : .9em;
}
/**********************************************************************/
/* NEW DETAILS PAGE UI ELEMTNS - Needs to be merged with other CSS and classes that are no longer needed should be deleted....but later */
/**********************************************************************/
.md-oncall-widget {
	padding : 0;
	margin : 0 0 15px 0;
	width : 100%;
	height : auto;
	background-color : #FFFFFF;
}
.md-oncall-widget-toolbar {
	min-height: 0;
	height : 30px;
	width : 100%;
}
.md-oncall-widget-toolbar-tools {
	padding : 0 10px 0 10px;
}
.md-widget-toolbar-title {
	display : inline-block;
	font-size : .7em;
	font-weight : 300;
	color : #FFFFFF;
}
.md-widget-icons-container {
	display : inline-block;
	font-size : .8em;
	float : right;
	width : auto;
}
.md-oncall-widget-body {
	overflow : hidden;
	width : 100%;
	height : auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all ease-in-out 0.15s;
	-moz-transition: all ease-in-out 0.15s;
	-ms-transition: all ease-in-out 0.15s;
	-o-transition: all ease-in-out 0.15s;
	transition: all ease-in-out 0.15s;
}
.ng-hide {
	height : 0;
}
/*.ng-show {*/
/*!*height : auto;*!*/
/*max-height : 1000px;*/
/*}*/
.md-oncall-widget-body .content {
	margin : 10px;
}
.md-oncall-widget-body.ng-hide {
	max-height : 0;
}
.md-widget-content {
	margin : 10px;
	overflow : hidden;
}
.md-widget-content .display-info {
	line-height : 1em;
}
.md-widget-content .display-info .display-info-line {
	margin-bottom : 7px;
}
.md-widget-content .display-info .info-label {
	display : inline-block;
	font-size: .9em;
	text-align: right;
	color : #929292;
}
.md-widget-content .display-info .info-data {
	display : inline-block;
	font-size: .9em;
	font-weight: 700;
	text-align: left;
	padding-left: 10px;
}
.md-widget-content .display-info-line .info-data-only {
	display : inline-block;
	font-size: .9em;
	font-weight: 700;
	text-align: left;
	/*padding-left: 10px;*/
}
.md-widget-content .display-info .info-note {
	display : inline-block;
	font-style: italic;
	font-size: .9em;
	color : #929292;
	text-align: left;
	padding-left: 10px;
}
/**********************************************************************/
/* NAVIGATION NOT ALLOWED MODAL */
/**********************************************************************/
.no-nav-panel {
	position : absolute;
	top : 10%;
	left : 50%;
	background-color : #FFFFFF;
	border-radius : 4px;
	padding : 10px;
	overflow : hidden;
}

/**********************************************************************/
/* RESPONSIVE CSS */
/**********************************************************************/
/* Coinsides with angular material flex layout-xl *//* Laptop Screen - Portrait */
@media only screen and (min-width: 1920px) {
	.left-side-widgets {
		width : 472px !important;
	}
	/*.right-side-widgets {*/
		/*width : auto;*/
	/*}*/
}
/* Laptop Screen - Portrait */
@media only screen and (max-width: 1280px) {
	.md-widget-content .display-info .display-info-line {
		margin-bottom : 4px;
	}
	.md-widget-content .display-info .info-label {
		font-size: .75em;
	}
	.md-widget-content .display-info .info-data {
		font-size: .75em;
	}
	.md-widget-content .display-info-line .info-data-only {
		font-size: .75em;
	}
	.md-widget-content .display-info .info-note {
		font-size: .75em;
	}
	.note {
		padding : 3px 0 3px 0;
		line-height: 1em;
		font-size : .75em;
	}
}
/* Coinsides with angular material flex layout-sm */
@media only screen and (max-width: 959px) {
	.right-side-widgets {
		padding : 0;
	}
}

/* iPad - Portrait */
@media only screen and (max-width: 768px) {

}

/* iPhone 5 - Portrait */
@media only screen and (max-width: 320px) {

}



/* Badge */
.badge {
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 0.5em 0.75em;
	position: relative;
}

.badge-red {
	background-color: #ff6666;
}

.pulsate::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-animation: pulse 1.3s ease infinite;
	animation: pulse 1.3s ease infinite;
	border-radius: 50%;
	border: 4px double #ff6666;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(2.5);
		transform: scale(2.5);
		opacity: 0;
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(2.5);
		transform: scale(2.5);
		opacity: 0;
	}
}


/* Notifications Switch */
.notifications-switch {
	margin: 0px;
}

md-switch .notifications-switch {
  position: relative;
  margin: 0px;
}

.notifications-switch div.md-container {
    position: absolute;
    margin-left: 56%;
    float: right;
}

.notifications-switch div.md-label {
    float: left;
    position: absolute;
    margin-left: 1%;
}


/* Bootstrap Inputs Invalid */
.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #d9534f;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }



  .invalid-feedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #d9534f;
  }

/* bs-callouts */
.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}

.bs-callout+.bs-callout {
    margin-top: -5px;
}

.bs-callout-success {
    border-left-color: #93C54B;;
}

.bs-callout-info {
    border-left-color: #1b809e;
}

.bs-callout-warning {
    border-left-color: #aa6708;
}

.bs-callout-danger {
    border-left-color: #ce4844;
}

