@media (min-width: 992px) {
	.nav-users {
		position: fixed;
	}
}

.remove-account-container {
	display: inline-block;
	position: relative;
}

.btn-remove-account {
	top: 10px;
	right: 10px;
	position: absolute;
}
