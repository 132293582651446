/*UI-GRID*/

.ui-grid-search .ui-grid-row:hover .ui-grid-cell{background:lightslategrey; cursor: pointer; cursor: hand;}
.ui-grid-search .ui-grid-top-panel {
    background: #0D478D;
    color: white;
    border-right: 0;
}
.ui-grid-search .ui-grid-cell
{
    border-right: 0;
}


/* UI-GRID Roadside Service */
.ui-grid-roadside .ui-grid-row .ui-grid-cell{
    border-bottom: solid 1px #6b6a6a50;
    border-right: solid 0px;
    background-color: rgb(255, 255, 255);
}
.ui-grid-roadside .ui-grid-row:hover .ui-grid-cell{
    cursor: pointer;
    background-color: rgb(157, 169, 175) !important;
    font-weight: 600;
}

.overdue{
    background-color: #df3b41 !important;
    color: black;
}


/* Roadside service history table */
.roadside-history-table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
}
.roadside-history-table thead th {
    background-color: #DDEFEF;
    border: solid 1px #DDEEEE;
    color: #336B6B;
    padding: 10px;
    text-align: left;
    text-shadow: 1px 1px 1px #fff;
}
.roadside-history-table tbody td {
    border: solid 1px #DDEEEE;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px #fff;
}