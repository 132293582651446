.help-page-logo-container {
    text-align: center;
    padding : 10px 0px;
}
.help-page-logo {
    width : 40%;
    height : auto;
    max-width : 500px;
}
.help-page-header {
    margin-bottom : 20px;
    padding : 5px 10px;
    font-size : 1.4em;
    line-height : 1.4em;
    text-align : center;
}
.help-page-logo img {
    width : 100%;
}
.support {
    padding : 5px;
}
.support-header {
    font-size : 2em;
    text-align : center;
}
.support-contact-info-container {
    overflow : hidden;
}
.support-contact-info {
    text-align: center;
}
.contact-icon {
    color : #FFFFFF;
    font-size : 2em;
    vertical-align : 0.175em;
}
.contact-icon:before {
    padding : 12px;
}
.contact-info {
    display : inline-block;
    padding-left : 0.5em;
}
.contact-info a {
    /*text-decoration: none;*/
    color : #FFFFFF;
}
.video-container {
    margin-bottom : 20px;
    background-color : #FFFFFF;
    min-height : 470px;
}
.video-container .header {
    padding : 10px;
    font-size : 1.5em;
    font-weight : 700;
}
.video-container .footer {
    padding : 10px;
    line-height: 1.4em;
}
.vid-box {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
}
.vid-box iframe,
.vid-box object,
.vid-box embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

