/* Background 2f2f2f */

.roadside-service-request-container {
    background-color: white;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
}
/*Attempt to mimic the global glyphicons class
    previously infesting this app*/
.font-awesome-icons {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.roadside-service-request-container .md-toolbar-tools {
    background-color: #2f2f2f;
}

.roadside-form {
    padding-top: 2em;
}

.w-100 {
    width:100%;
}

.breakdown-problem-radio {
    width: 8em;
    display: flex;
    align-items: center;
}


.r-customer-info > * > .form-group  {
    width: 100%;
}

.r-customer-info > .form-group  {
    width: 100%;
}
.r-location > * > .form-group  {
    width: 100%;
}

.r-location > .form-group  {
    width: 100%;
}

.roadside-service-request-container h1 {
    font-size: 30px;
    margin-bottom: 15px;
}

.request-complete-container {
    padding: 30px;
    margin-top: -30px;
    height: 1500px;
    text-align: center;
}


.roadside-service-logo-container {
    /* margin-top: 1.5em; */
    text-align: center;
}

.roadside-service-logo {
    max-width: 100%;
    max-height:75px;
    /* margin-bottom: 15px; */
    padding: 2px;
}


.error-message {
    color: rgb(221,44,0)
}

.request-complete-container .glyphicon {
    color: #4b803b;
    font-size: 40px;
}

.mobile-only {
    display: none;
}



/* Tablet */
@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .roadside-service-request-container md-toolbar .md-toolbar-tools {
        justify-content: center;
    }
    
}
  
/* Mobile */
@media screen and (max-width: 600px) {
    .mobile-only {
        display: block;
    }

    .roadside-form {
        padding-top: 0em;
        padding-left: 0em;
        padding-right: 0em;
    }

    .roadside-form-header {
        display: flex;
        justify-content: center;
        font-size: 0.7em !important;
    }

    .roadside-service-logo {
        max-width: 100%;
        max-height:50px;
    }

    .roadside-service-request-container md-toolbar .md-toolbar-tools {
        justify-content: center;
    }
 

}
  


/* Roadside Maddenco Copy Paste */

.maddenco-text-area {
    background-color: #2a2a2a;
    color: limegreen;
    border-color: limegreen;
}



/* Asterisk Corner Box */

.ui.form textarea, .ui.form input[type="text"], .ui.form input[type="email"], .ui.form input[type="date"], .ui.form input[type="password"], .ui.form input[type="number"], .ui.form input[type="url"], .ui.form input[type="tel"], .ui.form .ui.input {
    width: 100%;
}
.ui.form :last-child {
    margin-bottom: 0em;
}
.ui.input {
    font-size: 1em;
}
.ui.input {
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, 0.7);
}


.ui.labeled.input input {
    padding-right: 2.5em !important;
}
.ui.form textarea, .ui.form input[type="text"], .ui.form input[type="email"], .ui.form input[type="date"], .ui.form input[type="password"], .ui.form input[type="number"], .ui.form input[type="url"], .ui.form input[type="tel"] {
    margin: 0em;
    padding: 0.65em 1em;
    font-size: 1em;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 0.3125em;
    -webkit-transition: background-color 0.3s ease-out, -webkit-box-shadow 0.2s ease, border-color 0.2s ease;
    transition: background-color 0.3s ease-out, box-shadow 0.2s ease, border-color 0.2s ease;
    -webkit-box-shadow: 0em 0em 0em 0em rgba(0, 0, 0, 0.3) inset;
    box-shadow: 0em 0em 0em 0em rgba(0, 0, 0, 0.3) inset;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.ui.form textarea, .ui.form input[type="text"], .ui.form input[type="email"], .ui.form input[type="date"], .ui.form input[type="password"], .ui.form input[type="number"], .ui.form input[type="url"], .ui.form input[type="tel"], .ui.form .ui.input {
    width: 100%;
}
.ui.form :first-child {
    margin-top: 0em;
}
.ui.input input {
    width: 100%;
    font-family: "Helvetica Neue", "Helvetica", Arial;
    margin: 0em;
    padding: 0.65em 1em;
    font-size: 1em;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 0.3125em;
    -webkit-transition: background-color 0.3s ease-out, -webkit-box-shadow 0.2s ease, border-color 0.2s ease;
    transition: background-color 0.3s ease-out, box-shadow 0.2s ease, border-color 0.2s ease;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.ui.input > .ui.corner.label {
    top: 25px;
    right: 1px;
}
.ui.rounded.image > .ui.corner.label, .ui.input > .ui.corner.label, .ui.segment > .ui.corner.label {
    overflow: hidden;
}
.ui.labeled.input .corner.label {
    font-size: 0.7em;
    border-radius: 0 0.3125em;
}
.ui.corner.label {
    background-color: transparent;
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: 10;
    margin: 0em;
    width: 3em;
    height: 3em;
    padding: 0em;
    text-align: center;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}
.ui.label:last-child {
    margin-right: 0em;
}
.ui.form :last-child {
    margin-bottom: 0em;
}
.ui.label {
    font-size: 0.8125rem;
}
.ui.label {
    display: inline-block;
    vertical-align: middle;
    margin: -0.25em 0.25em 0em;
    background-color: #E8E8E8;
    border-color: #E8E8E8;
    padding: 0.5em 0.8em;
    color: rgba(0, 0, 0, 0.65);
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0.325em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.1s linear;
    transition: background 0.1s linear;
}

.ui.corner.label:after {
    position: absolute;
    content: "";
    right: 0em;
    top: 0em;
    z-index: -1;
    width: 0em;
    height: 0em;
    border-top: 0em solid transparent;
    border-right: 3em solid transparent;
    border-bottom: 3em solid transparent;
    border-left: 0em solid transparent;
    border-right-color: inherit;
    -webkit-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
}

.ui.corner.label .icon {
    font-size: 0.875em;
    margin: 0.5em 0em 0em 1.25em;
}
.ui.label .icon {
    width: auto;
}

/* Form Error */
.ui.form .fields.error .field .corner.label, .ui.form .field.error .corner.label {
    border-color: #D95C5C;
    color: #FFFFFF;
}

div.form-group.ui.input.has-error input{
    background-color: #FFFAFA;
    border-color: #E7BEBE;
    border-left: none;
    color: #D95C5C;
    padding-left: 1.2em;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-box-shadow: 0.3em 0em 0em 0em #D95C5C inset;
    box-shadow: 0.3em 0em 0em 0em #D95C5C inset;
}

div.form-group.ui.input.has-error div.ui.corner.label {
    border-color: #d95c5c
}

div.form-group.ui.input.has-error input {
    background-color: transparent;
}


.error {
    background-color: #FFFAFA;
    border-color: #E7BEBE;
    border-left: none;
    color: #D95C5C;
    padding-left: 1.2em;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-box-shadow: 0.3em 0em 0em 0em #D95C5C inset;
    box-shadow: 0.3em 0em 0em 0em #D95C5C inset;
}

div.error, div.error-list, label.error, input.error, select.error {
    color: #D95C5C !important;
    border-color: #D95C5C !important;
}

.ui.form .fields.error .field .corner.label, .ui.form .field.error .corner.label {
    border-color: #D95C5C;
    color: #FFFFFF;
}

.pilot-indicator {
  display: inline-block;
  margin-left: 20px;
  background-color: #ffb718;
  padding: 0px 13px;
  border-radius: 7px;
  font-weight: bolder;
  font-size: 15px;
}

.status-name {
  display: inline-block;
}

.form-pilot-indicator {
  background-color: #ffb718;
  padding: 10px;
  font-size: 15px;
  font-weight: bolder;
}